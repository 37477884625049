import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import formTypes from "@/store/shared/form/types";

export const namespace = "vacation-application";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	currentUser: "currentUser",
	availableVacations: "availableVacations"
};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch",
	fetchCalendar: "fetchCalendar",
	fetchSubstitutes: "fetchSubstitutes",
	addApprover: "addApprover",
	removeApprover: "removeApprover",
	withdraw: "withdraw",
	fetchEmployees: "fetchEmployees",
	fetchVacations: "fetchVacations",
	fetchYears: "fetchYears",
	fetchPermissions: "fetchPermissions",
	withdrawVacationApplication: "withdrawVacationApplication"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_APPLICATION: "SET_APPLICATION",
	SET_APPLICATION_STATE: "SET_APPLICATION_STATE",
	ADD_APPLICATION_APPROVER: "ADD_APPLICATION_APPROVER",
	REMOVE_APPLICATION_APPROVER: "REMOVE_APPLICATION_APPROVER",
	SET_REQUEST: "SET_REQUEST",
	SET_REQUEST_VACATION_ID: "SET_REQUEST_VACATION_ID",
	SET_REQUEST_START_DATE: "SET_REQUEST_START_DATE",
	SET_REQUEST_END_DATE: "SET_REQUEST_END_DATE",
	SET_REQUEST_SUBSTITUTE_ID: "SET_REQUEST_SUBSTITUTE_ID",
	SET_REQUEST_FILE_ID: "SET_REQUEST_FILE_ID",
	SET_REQUEST_BLOOD_DONATION_DATE: "SET_REQUEST_BLOOD_DONATION_DATE",
	SET_REQUEST_EDUCATION_INSTITUTION: "SET_REQUEST_EDUCATION_INSTITUTION",
	SET_REQUEST_EDUCATION_PURPOSE: "SET_REQUEST_EDUCATION_PURPOSE",
	SET_REQUEST_EDUCATION_DIRECTION: "SET_REQUEST_EDUCATION_DIRECTION",
	SET_REQUEST_WARRANT_TYPE: "SET_REQUEST_WARRANT_TYPE",
	SET_REQUEST_VACATION_DAYS: "SET_REQUEST_VACATION_DAYS",
	SET_REQUEST_REFERENCE_NUMBER: "SET_REQUEST_REFERENCE_NUMBER",
	SET_REQUEST_REFERENCE_SERIES: "SET_REQUEST_REFERENCE_SERIES",
	SET_REQUEST_REFERENCE_DAY: "SET_REQUEST_REFERENCE_DAY",
	SET_REQUEST_VACATION_TYPE: "SET_REQUEST_VACATION_TYPE",
	SET_ID: "SET_ID",
	SET_SUBSTITUTES: "SET_SUBSTITUTES",
	SET_CALENDAR_DATES: "SET_CALENDAR_DATES",
	SET_IS_SUBSTITUTES_LOADING: "SET_IS_SUBSTITUTES_LOADING",
	SET_IS_CALENDAR_LOADING: "SET_IS_CALENDAR_LOADING",
	SET_IS_APPROVER_ADDING: "SET_IS_APPROVER_ADDING",
	SET_IS_APPROVER_REMOVING: "SET_IS_APPROVER_REMOVING",
	SET_IS_WITHDRAWING: "SET_IS_WITHDRAWING",
	SET_EMPLOYEES: "SET_EMPLOYEES",
	SET_IS_EMPLOYEES_LOADING: "SET_IS_EMPLOYEES_LOADING",
	SET_IS_VACATIONS_LOADING: "SET_IS_VACATIONS_LOADING",
	SET_VACATIONS: "SET_VACATIONS",
	SET_IS_YEARS_LOADING: "SET_IS_YEARS_LOADING",
	SET_YEARS: "SET_YEARS",
	SET_PERMISSIONS: "SET_PERMISSIONS",
	SET_IS_VACATION_APPLICATION_WITHDRAWING: "SET_IS_VACATION_APPLICATION_WITHDRAWING"
};

const hrVacationApplicationTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default hrVacationApplicationTypes;
