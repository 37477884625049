import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";

export const namespace = "bank-account-application";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	...listingGetterTypes,
	lawyerStaffEmployees: "lawyerStaffEmployees",
	buttonUnlocked: "buttonUnlocked",
	getBankName: "getBankName"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	initializeSubModules: "initializeSubModules",
	getCurrentUser: "getCurrentUser",
	fetchDictionaries: "fetchDictionaries",
	tryFetchLKApplications: "tryFetchLKApplications",
	redirectToExistedInProgressApplication: "redirectToExistedInProgressApplication",
	tryFetchAgreementInfo: "tryFetchAgreementInfo",
	fetchStatusHistoryItems: "fetchStatusHistoryItems",
	tryFetchBankAccountApplication: "tryFetchBankAccountApplication",
	fetchBankAccountApplicationAccounts: "fetchBankAccountApplicationAccounts",
	initializeBankAccountApplicationInfo: "initializeBankAccountApplicationInfo",
	createBankAccountApplication: "createBankAccountApplication",
	updateBankAccountApplication: "updateBankAccountApplication",
	setSelectedBank: "setSelectedBank",
	setSelectedBankBranch: "setSelectedBankBranch",
	checkIsAccountValid: "checkIsAccountValid",
	sendApplicationLegalDepartmentRequest: "sendApplicationLegalDepartmentRequest",
	getMessages: "getMessages",
	createMessage: "createMessage",
	deleteMessage: "deleteMessage",
	setBik: "setBik",
	fetchSuggestedBankAndBankBranchByBik: "fetchSuggestedBankAndBankBranchByBik",
	updateStatusToReturnToDSP: "returnToDSP",
	updateStatusToConnect: "toConnect",
	updateStatusToReturnToUFKP: "returnToUFKP",
	fetchAccountUsagesById: "fetchAccountUsagesById",
	fetchAccountUsages: "fetchAccountUsages",
	downloadBankApplicationTemplate: "downloadBankApplicationTemplate",
	checkBankApplicationTemplateAvailability: "checkBankApplicationTemplateAvailability",
	fetchExistingApplications: "fetchExistingApplications",
	deleteBankAccountApplication: "deleteBankAccountApplication"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	SET_IS_DICTIONARIES_LOADING: "SET_IS_DICTIONARIES_LOADING",
	SET_IS_FETCH_BANK_ACCOUNT_APPLICATION_LOADING: "SET_IS_FETCH_BANK_ACCOUNT_APPLICATION_LOADING",
	SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING: "SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING",
	SET_IS_BANK_ACCOUNT_APPLICATION_BANK_FORM_VALID: "SET_IS_BANK_ACCOUNT_APPLICATION_BANK_FORM_VALID",
	SET_IS_BANK_ACCOUNT_APPLICATION_BANK_FORM_DISABLED: "SET_IS_BANK_ACCOUNT_APPLICATION_BANK_FORM_DISABLED",
	SET_IS_BANK_ACCOUNT_APPLICATION_ACCOUNTS_FORM_VALID: "SET_IS_BANK_ACCOUNT_APPLICATION_ACCOUNTS_FORM_VALID",
	SET_IS_BANK_ACCOUNT_APPLICATION_ACCOUNTS_FORM_DISABLED: "SET_IS_BANK_ACCOUNT_APPLICATION_ACCOUNTS_FORM_DISABLED",
	SET_IS_FETCH_STATUS_HISTORY_LOADING: "SET_IS_FETCH_STATUS_HISTORY_LOADING",
	SET_STATUS_HISTORY_ITEMS: "SET_STATUS_HISTORY_ITEMS",
	SET_BANKS: "SET_BANKS",
	SET_SIGN_STATUSES: "SET_SIGN_STATUSES",
	SET_BANK_ACCOUNT_TYPES: "SET_BANK_ACCOUNT_TYPES",
	SET_ELECTRONIC_DOCUMENT_STATUSES: "SET_ELECTRONIC_DOCUMENT_STATUSES",
	SET_PAPER_DOCUMENT_STATUSES: "SET_PAPER_DOCUMENT_STATUSES",
	SET_APPLICATION_STATUSES: "SET_APPLICATION_STATUSES",
	SET_CURRENCIES: "SET_CURRENCIES",
	SET_STAFF_EMPLOYEES: "SET_STAFF_EMPLOYEES",
	SET_FRP_EMPLOYEES: "SET_FRP_EMPLOYEES",
	SET_BANK_BRANCHES: "SET_BANK_BRANCHES",
	RESET_BANK_BRANCHES: "RESET_BANK_BRANCHES",
	SET_LK_APPLICATIONS: "SET_LK_APPLICATIONS",
	RESET_BIK: "RESET_BIK",
	SET_BIK: "SET_BIK",
	RESET_CORRESPONDENT_ACCOUNT: "RESET_CORRESPONDENT_ACCOUNT",
	SET_CORRESPONDENT_ACCOUNT: "SET_CORRESPONDENT_ACCOUNT",
	RESET_EDITABLE_ITEM: "RESET_EDITABLE_ITEM",
	SET_EDITABLE_ITEM: "SET_EDITABLE_ITEM",
	SET_EDITABLE_ITEM_SOURCE: "SET_EDITABLE_ITEM_SOURCE",
	SET_EDITABLE_ITEM_LOAN_APPLICATION_NUMBER: "SET_EDITABLE_ITEM_LOAN_APPLICATION_NUMBER",
	SET_EDITABLE_ITEM_AGREEMENT: "SET_EDITABLE_ITEM_AGREEMENT",
	RESET_EDITABLE_ITEM_BANK_ID: "RESET_EDITABLE_ITEM_BANK_ID",
	SET_EDITABLE_ITEM_BANK_ID: "SET_EDITABLE_ITEM_BANK_ID",
	RESET_EDITABLE_ITEM_BANK_BRANCH_ID: "RESET_EDITABLE_ITEM_BANK_BRANCH_ID",
	SET_EDITABLE_ITEM_BANK_BRANCH_ID: "SET_EDITABLE_ITEM_BANK_BRANCH_ID",
	SET_EDITABLE_ITEM_CONTRACT_NUMBER: "SET_EDITABLE_ITEM_CONTRACT_NUMBER",
	SET_EDITABLE_ITEM_CONTRACT_OPENING_DATE: "SET_EDITABLE_ITEM_CONTRACT_OPENING_DATE",
	SET_EDITABLE_ITEM_IS_LEGAL_DEPARTMENT_REQUEST_SENT: "SET_EDITABLE_ITEM_IS_LEGAL_DEPARTMENT_REQUEST_SENT",
	SET_EDITABLE_ITEM_STATUS: "SET_EDITABLE_ITEM_STATUS",
	RESET_EDITABLE_ITEM_ACCOUNTS: "RESET_EDITABLE_ITEM_ACCOUNTS",
	SET_EDITABLE_ITEM_ACCOUNTS: "SET_EDITABLE_ITEM_ACCOUNTS",
	ADD_EDITABLE_ITEM_ACCOUNTS_ITEM: "ADD_EDITABLE_ITEM_ACCOUNTS_ITEM",
	REMOVE_EDITABLE_ITEM_ACCOUNTS_ITEM: "REMOVE_EDITABLE_ITEM_ACCOUNTS_ITEM",
	SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_TYPE: "SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_TYPE",
	SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_NUMBER: "SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_NUMBER",
	SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_CURRENCY_ID: "SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_CURRENCY_ID",
	SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_OPENED_AT: "SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_OPENED_AT",
	SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_IS_ACCOUNT_VALID_CHECK_IN_PROGRESS: "SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_IS_ACCOUNT_VALID_CHECK_IN_PROGRESS",
	SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_IS_ACCOUNT_VALID: "SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_IS_ACCOUNT_VALID",
	SET_IS_DOCUMENTS_LOADING: "SET_IS_DOCUMENTS_LOADING",
	SET_DOCUMENT_FILE_INFO_ITEMS: "SET_DOCUMENT_FILE_INFO_ITEMS",
	SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_ELECTRONIC_STATUS: "SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_STATUS",
	SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_STATUS_CHANGING: "SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_STATUS_CHANGING",
	RESET_DECLINE_DOCUMENT_STATUS_EXPLANATION: "RESET_DECLINE_DOCUMENT_STATUS_EXPLANATION",
	SET_DECLINE_DOCUMENT_STATUS_EXPLANATION: "SET_DECLINE_DOCUMENT_STATUS_EXPLANATION",
	SET_IS_FETCH_SUGGESTED_BANK_AND_BANK_BRANCH_BY_BIK_LOADING: "SET_IS_FETCH_SUGGESTED_BANK_AND_BANK_BRANCH_BY_BIK_LOADING",
	SET_ACCOUNT_USAGES: "SET_ACCOUNT_USAGES",
	SET_APPLICATION_BANK_ACCOUNT_USAGE_TYPES: "SET_APPLICATION_BANK_ACCOUNT_USAGE_TYPES",
	RESET_EDITABLE_ITEM_ACCOUNT_USAGES: "RESET_EDITABLE_ITEM_ACCOUNT_USAGES",
	SET_EDITABLE_ITEM_ACCOUNT_USAGES: "SET_EDITABLE_ITEM_ACCOUNT_USAGES",
	SET_IS_RETURNING_TO_DSP: "SET_IS_RETURNING_TO_DSP",
	SET_IS_RETURNING_TO_UFKP: "SET_IS_RETURNING_TO_UFKP",
	SET_IS_STATUS_UPDATING_TO_CONNECT: "SET_IS_STATUS_UPDATING_TO_CONNECT",
	SET_IS_BANK_APPLICATION_TEMPLATE_DOWNLOADING: "SET_IS_BANK_APPLICATION_TEMPLATE_DOWNLOADING",
	SET_SIGNERS: "SET_SIGNERS",
	SET_BANK_SETTINGS: "SET_BANK_SETTINGS",
	SET_EXISTING_APPLICATIONS: "SET_EXISTING_APPLICATIONS"
};

const bankAccountApplicationTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default bankAccountApplicationTypes;
