import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import routeTypes from "@/store/shared/route/types";

export const namespace = "bank-account-applications-list";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	...listingGetterTypes,
	currentUser: "currentUser",
	isCuratorsDepartmentManagersIncludesCurrentUser: "isCuratorsDepartmentManagersIncludesCurrentUser",
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	fetchDictionaries: "fetchDictionaries",
	fetchSuggestedCounterparties: "fetchSuggestedCounterparties",
	setCuratorsDepartmentDefaultFilter: "setCuratorsDepatmentDefaultFilter"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_IS_DICTIONARIES_LOADING: "SET_IS_DICTIONARIES_LOADING",
	SET_BANKS: "SET_BANKS",
	SET_APPLICATION_STATUSES: "SET_APPLICATION_STATUSES",
	SET_BANK_ACCOUNT_TYPES: "SET_BANK_ACCOUNT_TYPES",
	SET_FILTER_LOAN_APPLICATION_NUMBER: "SET_FILTER_LOAN_APPLICATION_NUMBER",
	SET_FILTER_COUNTERPARTY_ID: "SET_FILTER_COUNTERPARTY_ID",
	SET_FILTER_STATUS: "SET_FILTER_STATUS",
	SET_FILTER_CURATORS_DEPARTMENT_EMPLOYEE_ID: "SET_FILTER_CURATORS_DEPARTMENT_EMPLOYEE_ID",
	SET_IS_FETCH_SUGGESTED_COUNTERPARTIES_LOADING: "SET_IS_FETCH_SUGGESTED_COUNTERPARTIES_LOADING",
	RESET_SUGGESTED_COUNTERPARTIES: "RESET_SUGGESTED_COUNTERPARTIES",
	SET_SUGGESTED_COUNTERPARTIES: "SET_SUGGESTED_COUNTERPARTIES",
	SET_CURATORS_DEPARTMENT_MANAGERS: "SET_CURATORS_DEPARTMENT_MANAGERS",
	SET_FILTER_IS_ALL_CURATORS_DEPARTMENT_EMPLOYEES: "SET_FILTER_IS_ALL_CURATORS_DEPARTMENT_EMPLOYEES",
	SET_FILTER: "SET_FILTER",
	SET_BANK_SETTINGS: "SET_BANK_SETTINGS"
};

const bankAccountApplicationsListTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default bankAccountApplicationsListTypes;
